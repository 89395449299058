<template>
  <v-container>
    <ServiceReport></ServiceReport>
  </v-container>
</template>

<script>
//import ServiceReport from "@/view/pages/service-form/Soled-Engineering/BATT_S1";
export default {
  /*components: {
    ServiceReport
  }*/
};
</script>
